import React from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import Section from "../../components/Section"
import { graphql } from "gatsby";
import Col from 'react-bootstrap/Col';
import Headline from "../../components/Headline"
import Teaser from "../../components/Teaser";

export default class WarriorWorshipVideos extends React.Component {
  render() {
    let musicVideos = this.props.data.musicVideos.edges.map(({ node }) => node);
    let lyricVideos = this.props.data.lyricVideos.edges.map(({ node }) => node);
    return ( 
      <Layout>
        <Seo title="Warrior Worship Music Videos" />
        <Section className="white" useCol={false}>
          <Col xs={12}>
            <Headline>Warrior Worship Music Videos</Headline>
          </Col>
          {musicVideos.map((song) => {
            return (
              <Col md={4} key={"song-" + song.slug} className="gridListing mb-4">
                <Teaser content={song} showDescription={false} />
              </Col>
            );
          })}
          {lyricVideos.map((song) => {
            return (
              <Col md={4} key={"song-" + song.slug} className="gridListing mb-4">
                <Teaser content={song} showDescription={false} />
              </Col>
            );
          })}
        </Section>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    musicVideos: allContentfulWarriorWorshipSong(sort: {fields: [album___releaseDate, orderOnAlbum], order: [DESC, ASC]}, filter: {isLyricVideo: {ne: true}, vimeoId: {ne: null}}) {
      edges {
        node {
          ...Teaser
        }
      }
    }
    lyricVideos: allContentfulWarriorWorshipSong(sort: {fields: [album___releaseDate, orderOnAlbum], order: [DESC, ASC]}, filter: {isLyricVideo: {eq: true}, vimeoId: {ne: null}}) {
      edges {
        node {
          ...Teaser
        }
      }
    }
  }
`